<template>
  <v-container fluid tag="section">

        <v-dialog  v-model="alert_msg"  width="500">

           <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Mensagem
        </v-card-title>

        <v-card-text>
            {{msg_retorno}}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="alert_msg = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>


      
      
       </v-dialog>


<base-material-card
      icon="mdi-account"
      title="Alteração de Senha"
      class="px-5 py-3"
    >
    <v-form ref="form">
        <v-card-text>
            <v-container>
              <v-row>
             
                  <v-col cols="6">
                    <v-text-field  outlined  clearable
                      placeholder="Senha Atual" v-model="senhaAtual"
                      type="password"      prepend-icon="mdi-key">
                    </v-text-field>
                  </v-col>
            </v-row>

            <v-row>
    
                  <v-col cols="6">
                    <v-text-field
                      placeholder="Nova Senha" v-model="senhaNova" outlined  clearable
                      type="password"    prepend-icon="mdi-key"             >
                    </v-text-field>
                  </v-col>

                 
              </v-row>
              

            </v-container>
        </v-card-text>
        </v-form>  
</base-material-card>

 <v-btn color="success"  :loading="btn_senha"  @click="update()" >Atualizar 
                   <v-icon right
                      dark>
                      mdi-refresh
                    </v-icon>
               </v-btn>

  </v-container>


</template>

<script>
  import Usuario from '../../services/usuario'

export default {

  created() {
          if(this.$store.state.auth.status.loggedIn == false){
           this.$router.push('/')
          }
  },

  data () {
      return {
        dlg: true,
        btn_senha: false,
        name: 'Register',
        senhaAtual: '',
        senhaNova: '',
        msg_retorno: '',
        alert_msg: false
      }
  },
  methods: {

  update(){
          this.btn_senha = true
          Usuario.updateSenhaUsuario(this.senhaNova, this.senhaAtual).then(response => {
          this.msg_retorno = response.data;
          this.alert_msg = true;
          this.$refs.form.reset();


        }).catch(e => {
           this.btn_senha = false

        }).finally(() => {
           this.btn_senha = false

        })
      },
  }


}
</script>
